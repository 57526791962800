import { AdobeEvent } from './types';

declare global {
  interface Window {
    adobeDataLayer?: any[];
  }
}

/**
 * Tracks an event in AdobeLaunch
 */
export const trackAdobeEvent = (event: AdobeEvent) => {
  if (window.adobeDataLayer) {
    window.adobeDataLayer.push(event);
  }
};

/**
 * Installs Adobe Launch script
 */
export const installAdobeLaunch = (adobeLaunchScriptUrl: string) => {
  window.adobeDataLayer = window.adobeDataLayer || [];

  // Load the Adobe Launch script
  const script = document.createElement('script');
  script.src = adobeLaunchScriptUrl;
  script.async = false;
  document.head.append(script);
};

/**
 * Gets the current date in MM/DD/YYYY format (used in many Adobe events)
 */
export const getFormattedDate = () => dateFormat.format(new Date());

const dateFormat = Intl.DateTimeFormat(['en-US'], {
  month: '2-digit',
  day: '2-digit',
  year: 'numeric'
});
